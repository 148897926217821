
              @import "@/assets/css/variables.scss";
            






















.portal_switch {
  cursor: pointer;
  padding: 2px;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $border-color-base;
  display: flex;
  margin: 0 8px;

  .client,
  .ib {
    width: 44px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
  }

  .client {
    background-color: $primary;
    color: #fff;
  }
}
