
              @import "@/assets/css/variables.scss";
            















































































































.notice_icon {
  font-size: 20px;
  cursor: pointer;
  margin: 0 8px;
}
