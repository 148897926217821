
              @import "@/assets/css/variables.scss";
            



























































































































































.login_dropdown_box {
  padding: 8px;

  .el-dropdown-menu__item {
    line-height: 40px;
    font-size: 14px;
    border-radius: 4px;
  }

  .name_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: rgba($warning, 0.2);
    border-radius: 4px;
    padding: 12px;

    .member-logo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: $text-secondary;
    }
  }
}
