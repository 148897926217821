.wrapper {
  background: $background-color-base;
  height: 100vh;
  overflow: hidden;
  .content-box {
    width: 100%;
    padding-top: 35px;
    min-height: calc(100vh - 122px - 74px);
    padding-bottom: 178px;
    overflow: hidden;
    .inner {
      width: 100%;
      max-width: 1600px;
      padding: 0 20px;
      margin: 0 auto;
    }
    h3 {
      font-size: 17px;
      color: $blue;
      line-height: 1.799;
      margin-bottom: 50px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 1024px) {
  .wrapper .content-box {
    padding-top: 0;
    .inner {
      padding: 10px;
    }
  }
}
@media (max-width: 767px) {
  .wrapper .content-box {
    .inner {
      padding: 20px;
    }
    h3 {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
}
#launcher {
  @include rtl-sass-prop(left, right, 0);
}
