
              @import "@/assets/css/variables.scss";
            




























































































.status_session {
  display: flex;
}
